.solution-block-main {
  padding: 50px 0;
  background: linear-gradient(to bottom, #ffffff, #F6F6F6);
  .container {
    display: flex;

    .text {
      margin-left: 30px;
      h1{
        font-size: 32px;
      }
      div {
        margin-bottom: 20px;
      }
    }
  }
}

.solution-block-advantage {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;

  .advantage-text-block {
    text-align: center;

    svg {
      width: 75px;
      fill: #0187E6;
    }

    h4 {
      text-transform: uppercase;
      margin: 10px 0;
    }

    span {
      color: #545454;
    }
  }
}

.solution-block-work {
  background: #F6F6F6;
  padding: 50px 0;

  .container {
    display: flex;

    .text {
      padding-right: 80px;
    }
  }
}

.solution-block-chart {
  background-image: url("/assets/image/solution/dashed.png");
  background-repeat: repeat-x;
  background-position: center;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;

  .chart-block {
    text-align: center;

    .text {
      width: 200px;
      margin: 0 auto;
      color: #545454;
      height: 45px;
      padding-bottom: 20px;
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      padding-top: 10px;
    }
  }
}

.solution-block-problems {
  padding: 50px 0;
  background: #01BAF8;

  .problem-block-group {
    display: flex;
    justify-content: space-around;
    padding-top: 40px;

    .problem-block {
      background: #ffffff;
      width: 190px;
      text-align: center;
      padding: 30px;
      border-radius: 10px;
      box-shadow: 1px 1px 40px rgba(0, 0, 0, 0.3);

      svg {
        width: 80px;
        padding-bottom: 10px;
        fill: #01BAF8;
      }
    }
  }

  h2 {
    color: #ffffff;
    width: 1000px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
  }
}

.solution-block-future {
  margin: 50px 0;

  h2 {
    text-align: center;
    text-transform: uppercase;
  }

  .future-block-group {
    display: flex;
    justify-content: space-between;

    .future-block {
      display: flex;
      align-items: center;
      width: 300px;

      h4 {
        text-transform: uppercase;
      }

      svg {
        width: 100px;
        padding-right: 10px;
        fill: #01BAF8;
      }
    }
  }

  .grey-block {
    background: #F3F3F3;
    border-radius: 10px;
    padding: 40px;
    text-transform: uppercase;
    text-align: center;

    h4 {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
}

.solution-block-office {
  background: #01BAF8;

  .container {
    display: flex;
    padding-top: 30px;
    color: #ffffff;
    justify-content: space-around;

    img {
      margin-bottom: -7px;
    }

    h2 {
      text-transform: uppercase;
      width: 600px;
    }

    ul {
      padding-bottom: 50px;
      font-weight: bold;

      li {
        padding: 3px 0;
      }
    }
  }
}

.solution-block-confidence {
  margin: 50px 0;

  h2 {
    text-transform: uppercase;
    text-align: center;
  }

  .confidence-block-group {
    display: flex;
    justify-content: space-between;

    &:last-child {
      padding-top: 30px;
    }

    .confidence-block {
      width: 50%;
      display: flex;
      align-items: center;

      &:first-child {
        padding-right: 30px;
      }

      span {
        width: 460px;
      }
    ;
    }

    svg {
      width: 70px;
      padding-right: 10px;
      fill: #00BBF7;
    }
  }

}