.technology-block-main {
  padding: 50px 0;
  background: linear-gradient(to bottom, #ffffff, #F6F6F6);
  h1{
    font-size: 32px;
  }

  .container {
    display: flex;

    .text {
      margin-right: 30px;

      div {
        margin-bottom: 20px;
      }
    }
  }
}

.blue {
  color: #0099E7;
  text-transform: initial;

  &.bold {
    font-weight: bold;
  }
}

.technology-block-lorawan {
  margin: 50px 0;

  .technology-advantage-block-group {
    display: flex;
    justify-content: space-between;

    &:nth-child(2n) {
      margin: 30px 0;
    }

    .technology-advantage-block {
      display: flex;
      align-items: center;

      svg {
        fill: #008AC6;
        width: 80px;
        margin-right: 20px;
      }

      span {
        width: 250px;
      }
    }
  }
}

.technology-block-opportunities {
  background: #F6F6F6;
  padding: 50px 0;

  h2 {
    text-transform: uppercase;
  }

  .opportunities-block-group {
    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-top: 30px;
    }

    .opportunities-block {
      width: 310px;
      height: 160px;
      border-radius: 10px;
      box-shadow: inset 0 -100px 50px -50px rgba(0, 0, 0, 0.7);
      padding: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      div {
        color: #ffffff;
        font-weight: bold;
        font-size: 22px;
        text-transform: uppercase;
      }
    }
  }

}