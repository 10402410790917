.news-block-main {
  padding: 50px 0;

  h2 {
    text-transform: uppercase;
  }

  .news-block-group {
    .news-block {
      background: #F6F6F6;
    }
    a{
      text-decoration: none;
      color: inherit;
    }
    .details{
      padding-top: 10px;
      color: #005BFF;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  ul.pagination {
    padding: 0;
    background: #F6F6F6;
    text-align: center;
    border-radius: 10px;

    li {
      height: 50px;
      padding: 10px 5px;
      display: inline-block;

      &:first-child {
        float: left;
        border-right: 1px solid #E3E3E3;
        font-weight: bold;

        a {
          padding: 15px 80px;
        }
      }

      &:last-child {
        float: right;
        border-left: 1px solid #E3E3E3;
        font-weight: bold;

        a {
          padding: 15px 80px;
        }
      }

      &.selected {
        a {
          background: #ffffff;
          border-radius: 50%;
          box-shadow: 0 10px 20px #c9c9c9;
        }
      }

      a {
        display: block;
        cursor: pointer;
        padding: 13px 20px;
        width: 15px;

        &:focus {
          outline: none;
        }
      }
    }
  }


}

.detail-news-block-main {
  padding: 50px 20px;
  div{
    padding-top: 20px;
  }
  h2 {
    text-transform: uppercase;
  }
  img{
    border-radius: 10px;
  }
  blockquote{
    background: #F6F6F6;
    margin: 0;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    &:before{
      align-self: flex-start;
      content:url("/assets/image/news/left.png");
      margin-right: 20px;
    }
    &:after{
      content: url("/assets/image/news/right.png");;
      align-self: flex-end;
      margin-left: 20px;
    }
    a{
      display: inline-block;
      padding-top: 10px;
      color: #005BFF;
      text-decoration: none;
      &:hover{
        border-bottom: none;
        text-decoration: underline;
      }
    }
  }
}

hr{
  border: none;
  background-color: #C2C2C2;
  height: 1px;
  clear: both;
}

