.about-block-main {
  padding: 50px 0;

  h2 {
    text-transform: uppercase;
  }
}

.about-block-project{
  padding: 50px 0;
  background: #F6F6F6;
  h2 {
    text-transform: uppercase;
  }

}

