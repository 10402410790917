@import "home";
@import "solution";
@import "technology";
@import "about";
@import "404";
@import "news";
@import "contacts";


@font-face {
  font-family: 'Engravers Gothic';
  src: url('../fonts/EngraversGothic.eot');
  src: local('Engravers Gothic'), local('EngraversGothic'),
  url('../fonts/EngraversGothic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EngraversGothic.woff2') format('woff2'),
  url('../fonts/EngraversGothic.woff') format('woff'),
  url('../fonts/EngraversGothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/calibri.eot');
  src: local('calibri'), local('calibri'),
  url('../fonts/calibri.eot?#iefix') format('embedded-opentype'),
  url('../fonts/calibri.woff2') format('woff2'),
  url('../fonts/calibri.woff') format('woff'),
  url('../fonts/calibri.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Regular';
  //src: url('fonts/Montserrat-Regular.eot');
  src: local('Montserrat-Regular'), local('Montserrat-Regular'),
    //url('fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    //url('fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat-Regular", serif;
  color: #1A1A1A;
}

header {
  flex: 0 0 auto;
}

main {
  flex: 1 0 auto;
  line-height: 1.5;
}

footer {
  flex: 0 0 auto;
}

html, body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

h1 {
  font-size: 22px;
}

.container {
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  width: 100%;
  margin: 0 auto;
}

.logo-horizontal {
  display: flex;
  flex-direction: row;

  .logo {
    display: flex;
    align-items: center;
    width: 220px;
    text-decoration: none;
    color: inherit;

    &:visited {
      color: inherit;
    }

    .logo-name {
      border-right: 1px solid #BCBCBC;
      padding-right: 10px;
      width: 100%;

      .title {
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-family: "Engravers Gothic", serif;
        font-size: 25px;
      }

      .subtitle {
        text-transform: uppercase;
        display: flex;
        font-family: "Calibri", serif;
        justify-content: center;
        letter-spacing: 0.4em;
      }
    }
  }

  .tagline {
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 195px;
  }

}


.header {
  display: flex;
  flex-direction: row;
  height: 65px;
  padding: 20px 0;
  font-size: 14px;

  .info {
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-left: 1px solid #F0F0F0;

    .wrp:nth-child(2n) {
      padding-left: 10px;
    }

    &.auth {
      a {
        border-bottom: 1px dashed #1A1A1A;

        &:hover {
          border-bottom: none;
        }
      }
    }

    .block {
      display: flex;
      padding: 5px;

      .icon {
        width: 20px;
        height: 20px;
        padding-right: 10px;

        path {
          fill: #0187E6;
        }
      }

      a {
        color: #1A1A1A;
        text-decoration: none;
      }
    }
  }
}

footer {
  flex-shrink: 0;
  padding: 24px 0;
  color: #A5A5A5;
  font-size: 14px;
  background: #292929;

  .container {
    display: flex;
  }


  .footer-menu {
    width: 100%;

    .main-menu {
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        padding: 0 10px;
        text-decoration: none;
        font-weight: normal;
        color: #A5A5A5;

        &.active {
          color: #FFFFFF;
        }
      }
    }
  }

}

.header-menu {
  background: #F6F6F6;
  font-size: 20px;

  .main-menu {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      padding: 0 25px;
      text-decoration: none;
      font-weight: normal;
      color: #A7A7A7;

      &:hover {
        color: #242424;
      }
    }

    .active {
      color: #242424;
    }
  }

}


.btn {
  padding: 20px 30px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 16px;
  font-family: "Montserrat-Regular", serif;
  cursor: pointer;



  &.btn-primary {
    background: linear-gradient(to right, #0095E3, #00CAFD);
    color: #FFFFFF;
    height: 60px;
    &:hover{
      box-shadow: 0 0 15px #00CAFD;
      background: linear-gradient(to right, #0095E3, #00CAFD);
    }
  }

  &.btn-blue {
    color: #00ABEF;
    border: 1px solid #00ABEF;
    background: transparent;
    height: 60px;
  }

  &.btn-white {
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    background: transparent;
    height: 60px;
    &:hover{
      color: #00ABEF;
    }
  }

  &:hover{
    background: #EBF8FE;
  }
}


.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.popup_inner {
  position: relative;
  top: 25%;
  margin: 0 auto;
  max-width: 900px;
  background: #01BAF8;
  border-radius: 10px;
  padding: 20px 40px;

  h2 {
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
  }

  .text {
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 30px;
    font-size: 17px;
  }

  .close {
    background: none;
    border: none;
    float: right;
    margin-right: -20px;
    cursor: pointer;

    svg {
      width: 15px;
      fill: #FFFFFF;
    }
  }

  form {
    &.horizontal {
      display: flex;
      justify-content: space-between;
    }

    .group {
      position: relative;
      margin-bottom: 30px;

      &.error {
        &, label {
          color: red;
        }

        input {
          border-bottom-color: red;
        }

        div {
          margin: 10px;
          font-size: 18px;
        }
      }

    }

    input {
      font-size: 15px;
      padding: 10px;
      display: block;
      border: none;
      border-bottom: 1px solid #FFFFFF;
      background: #01BAF8;

      &:focus {
        outline: none;
      }
    }

    label {
      color: #FFFFFF;
      font-size: 15px;
      position: absolute;
      pointer-events: none;
      left: 10px;
      top: 12px;
      transition: 0.2s ease all;

    }

    input:focus ~ label, input:valid ~ label {
      top: -15px;
      font-size: 12px;
      color: #FFFFFF;
    }

    .btn {
      padding: 15px 30px;
      height: 50px;
    }
  }

  .error {
    color: red;
  }
  a{
    color:#ffffff;
    text-decoration: none;
  }
}

.block-arrow {
  margin-top: 40px;

  div {
    margin-top: -5px;
    text-align: center;
  }

  i {
    display: inline-block;
    padding: 5px;
    border-radius: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

    &.one {
      border: solid rgba(255, 255, 255, 0.3);
      border-width: 0 5px 5px 0;
    }

    &.two {
      border: solid rgba(255, 255, 255, 0.6);
      border-width: 0 5px 5px 0;
    }

    &.three {
      border: solid rgba(255, 255, 255, 1);
      border-width: 0 5px 5px 0;
    }
  }
}

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.clear {
  clear: both;
}

.news-block-group {
  .news-block {
    &:nth-child(3n+2) {
      margin: 0 30px;
    }

    margin: 0 auto;
    margin-bottom: 20px;
    min-height: 560px;
    width: 360px;
    float: left;
    border-radius: 10px;
    background: #ffffff;

    img {
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 250px;
    }

    .body {
      padding: 20px;

      h4 {
        margin: 0 0 10px 0;
        font-weight: bold;
      }
    }
  }
}
