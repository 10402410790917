.contacts-block-main{
  padding-top: 50px;

  h2 {
    text-transform: uppercase;
  }
}

.contacts-block-contact{
  padding: 50px 0;
  .container{
    display: flex;
    h4{
      text-transform: uppercase;
      margin-top: 0;
    }
    .block{
      background: #F6F6F6;
      border-radius: 10px;
      padding: 30px;
      ul{
        list-style: none;
       padding-left: 0;
        li{
          padding: 5px 0;
          a{
            color: #1A1A1A;
            text-decoration: none;
          }
        }
        svg{
          width: 20px;
          fill: #0187E6;
          padding-right: 7px;
          position: relative;
          top: 3px;
        }
      }
      &.requisites{
        flex-grow: 3;
        margin-right: 50px;
      }
      &.contacts{
        flex-grow: 2;
      }
    }


  }
}