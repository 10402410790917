.error-code {
  display: flex;
  margin-top: 150px;
  align-items: center;
  justify-content: center;
}

.error-text {
  text-align: center;
  color: #979797;

  a {
    padding-top: 30px;
    color: #0092D9;
    text-decoration: none;
    border-bottom: 1px dashed #0092D9;

    &:hover {
      border-bottom: none;
    }
  }
}


