.home-block-main {
  background-image: url("../image/main/background.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  //height: 500px;

  .container {
    display: flex;
    padding: 40px 0;

    .text {
      padding: 30px;

      .btn-group {
        margin-top: 30px;

        .btn-blue {
          margin-left: 20px;
        }
      }
    }

  }
}

.home-block-helpful {
  padding-top: 20px;
  h2 {
    font-size: 22px;
  }
  h2 {
    text-align: center;
    text-transform: uppercase;
  }

  .btn-group {
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      text-align: left;
      align-items: center;
      width: 355px;
      padding: 30px;
      text-transform: uppercase;
      font-size: 18px;
      border-radius: 10px;
      border: 1px solid #DEDAD9;
      background: #FFFFFF;
      cursor: pointer;
      &:hover{
        background: #EBF8FE;
      }

      svg {
        width: 80px;
        padding-right: 20px;
        fill: #0187E6;
      }
      &:focus{
        outline: none;
      }
      &.active {
        background: linear-gradient(to right, #0095E3, #00CAFD);
        color: #FFFFFF;

        svg {
          fill: #FFFFFF;
        }
      }
    }
  }

  .collapse {
    margin: 30px 0;
    box-shadow: 0 0 40px #D6D6D6;
    padding: 10px 0 10px 10px;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: 250px;

    &:before {
      content: "";
      border: solid transparent;
      position: absolute;
      bottom: 100%;
      border-bottom-color: #FFFFFF;
      border-width: 9px;
      margin-left: 0;
    }

    &.left {
      &:before {
        left: 170px;
      }
    }

    &.center {
      &:before {
        left: 560px;
      }
    }

    &.right {
      &:before {
        left: 950px;
      }
    }

    .text {
      padding: 20px;

      span {
        display: inline-block;
        padding-top: 10px;
      }
    }
  }

}

.home-block-about {
  margin-top: 50px;

  .container {
    display: flex;
    justify-content: space-between;

    h2 {
      text-transform: uppercase;
    }
  }

  img {
    margin-bottom: -10px;
  }

  .text {
    margin-left: 20px;

    h2 {
      margin: 0;
    }

    p {
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }
}

.home-block-and {
  background: url("../image/main/background-blue.jpg") repeat;
  margin-top: -10px;

  .container {
    background: radial-gradient(circle farthest-side at 700px, #00BBF7, transparent 100%);
    padding: 30px 0;

    h2 {
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    .block-group {
      display: flex;
      justify-content: space-between;

      .icon-text-block {
        background: #FFFFFF;
        display: flex;
        align-items: center;
        border-radius: 10px;
        width: 280px;
        padding: 35px;
        box-shadow: 1px 1px 40px rgba(0, 0, 0, 0.30);

        h3 {
          margin-top: 0;
          margin-bottom: 10px;
          color: #1A1A1A;
          text-transform: uppercase;
        }

        span {
          color: #4C4C4C;
        }

        svg {
          width: 110px;
          fill: #0187E6;
          padding-right: 20px;
        }
      }

    }

  }
}

.home-block-presentation {
  padding: 30px 0 60px 0;
  text-align: center;

  h2 {
    text-transform: uppercase;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
}
